import * as React from "react";
import { useMeetingLiveShareContext } from "../../../../processes/meetingLiveShare/MeetingLiveShareContext";
import { Emoji, emojisImagesMap } from "./FlyingEmojis.styles";

export const FlyingEmojis = () => {
  const { users } = useMeetingLiveShareContext();

  return (
    <>
      {users.map((u, index) =>
        u.mood ? <Emoji key={u.userId} src={emojisImagesMap[u.mood]} /> : null
      )}
    </>
  );
};
