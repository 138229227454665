import * as React from "react";
import Button from "@mui/material/Button";
import { iconUrl } from "./CameraEnableDialog.styles";
import { Title, Image, Text } from "./CameraEnableDialog.styles";

export const CameraNotReadableSAPDialogContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  // const locale = useLocale();
  return (
    <div className="text-center relative p-4">
      <Image className="mx-auto" src={iconUrl} alt="" />
      <Title>Camera access issue:</Title>
      <Text>
        If your camera does not work, please go to the SAP Software Center /
        Self Service and install the Microsoft Teams reset to set back your
        camera settings.
        <br />
        You will then have full access to Breakthru.
      </Text>
      <Button variant="outlined" onClick={onClose} className="mt-5">
        Close
      </Button>
    </div>
  );
};
