import * as React from "react";
import { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { SubscriptionContext } from "./SubscriptionContext";
import {
  fetchDataActionAxios,
  fetchDataReducer,
  initialState,
} from "../../utils/reducer";
import { endpoints } from "../../common/constants";
import { useAuthContext } from "../auth/AuthContext";
import { ISubscriptionResponse } from "../../common/types/IMicrosoftStoreSubscription";

const CLOSED_FOR_FREE_COUNTRY = "CLOSED_FOR_FREE_COUNTRY";

export const SubscriptionProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isClosedForFreeCountry, setIsClosedForFreeCountry] = useLocalStorage(
    CLOSED_FOR_FREE_COUNTRY,
    false
  );

  const [subscriptionState, dispatch] = useReducer(
    fetchDataReducer<ISubscriptionResponse>(),
    initialState
  );
  const { authorized } = useAuthContext();

  useEffect(() => {
    if (authorized) {
      fetchDataActionAxios(dispatch, { url: endpoints.checkSubscription });
    }
  }, [authorized]);

  const getCurrentSubscription = useCallback(async () => {
    if (authorized) {
      return fetchDataActionAxios(dispatch, {
        url: endpoints.checkSubscription,
      });
    }
  }, [authorized]);

  const value = useMemo(() => {
    return {
      isClosedForFreeCountry,
      subscriptionState,
      setIsClosedForFreeCountry,
      getCurrentSubscription,
    };
  }, [
    isClosedForFreeCountry,
    subscriptionState,
    setIsClosedForFreeCountry,
    getCurrentSubscription,
  ]);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};
