import * as React from "react";
import { Route } from "react-router";
import { Layout } from "../demo/components/Layout";

const IframePage = React.lazy(() => import("./pages/IframePage"));
const StartPage = React.lazy(() => import("./pages/StartPage"));
const BreatheVimeoPage = React.lazy(
  () => import("./pages/BreatheVimeoPage/BreatheVimeoPage")
);
const FinalPage = React.lazy(() => import("./pages/FinalPage"));

export const WalmartSupportPageRouter = (
  <Route
    path="/walmart-support"
    element={<Layout title={`[Walmart Support]`} />}
  >
    <Route path="iframe/:title" element={<IframePage />} />
    <Route path="vimeo-breathe" element={<BreatheVimeoPage />} />
    <Route path="final/:mood" element={<FinalPage />} />
    <Route index element={<StartPage />} />
  </Route>
);
