import * as React from "react";
import { Route } from "react-router";
import { StartChallengeExtensionLayout } from "./pages/StartChallengeExtension/components/StartChallengeExtensionLayout";

const ChooseChallenge = React.lazy(
  () => import("./pages/StartChallengeExtension/pages/ChooseChallenge")
);
const PreviewChallenge = React.lazy(
  () => import("./pages/StartChallengeExtension/pages/PreviewChallenge")
);
const GiftExtension = React.lazy(
  () => import("./pages/GiftExtension/GiftExtension")
);
const MeetingExtension = React.lazy(() => import("./pages/MeetingExtension"));

export const TeamsMessageExtensionRouter = (
  <Route path="/action">
    <Route path="personal" element={<GiftExtension />} />
    <Route path="channel" element={<GiftExtension />} />
    <Route path="groupChat" element={<GiftExtension />} />
    <Route path="meeting" element={<MeetingExtension />} />
    <Route path="startChallenge" element={<StartChallengeExtensionLayout />}>
      <Route path="choose" element={<ChooseChallenge />} />
      <Route path="preview/:type" element={<PreviewChallenge />} />
    </Route>
  </Route>
);
