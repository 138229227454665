import * as React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsContext } from "../teamsPage/contexts/AppInsightsContext";
import { AuthProvider } from "../processes/auth/AuthProvider";
import { SubscriptionProvider } from "../processes/subscription/SubscriptionProvider";
import { useAppInsightInit } from "../utils/appInsights/useAppInsightInit";
import { WelcomeGuideProvider } from "../processes/welcomeGuide/WelcomeGuideProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthErrorModal } from "../widgets/AuthErrorModal/AuthErrorModal";
import { TeamsProvider } from "../processes/teamsInit/TeamsProvider";
import { EnterpriseSharingProvider } from "../processes/enterpriseSharing/EnterpriseSharingProvider";
import { Outlet } from "react-router";
import { MsalProviderLayout } from "./MsalProviderLayout";
import { msalConfig } from "../processes/auth/contants";

const msalInstance = new PublicClientApplication(msalConfig);
const appInsightsContext = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  },
});

export const TeamsLayout: React.FC = () => {
  // TODO: do we need to track if statistics is initialized ?
  useAppInsightInit(appInsightsContext);

  return (
    <TeamsProvider>
      {/* TODO: we do not provide a setter for this context, so there is no need to use context, it's just a static data. */}
      <AppInsightsContext.Provider value={appInsightsContext}>
        <MsalProviderLayout msalInstance={msalInstance}>
          <AuthProvider>
            <SubscriptionProvider>
              <WelcomeGuideProvider>
                <EnterpriseSharingProvider>
                  <AuthErrorModal />
                  <Outlet />
                </EnterpriseSharingProvider>
              </WelcomeGuideProvider>
            </SubscriptionProvider>
          </AuthProvider>
        </MsalProviderLayout>
      </AppInsightsContext.Provider>
    </TeamsProvider>
  );
};
