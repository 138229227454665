import * as React from "react";
import { MoodsProvider } from "../../play/api/moods/MoodsProvider";
import { MeetingSocketProvider } from "../../../processes/meetingStage/MeetingSocketProvider";
import { MeetingLiveShareProvider } from "../../../processes/meetingLiveShare/MeetingLiveShareProvider";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { meetingUrlBase } from "../constants";
import { FrameContexts } from "@microsoft/teams-js";

export const Layout = () => {
  const [frameContext, setFrameContext] = useState<FrameContexts>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    microsoftTeams.getContext((context) => {
      if (
        context.frameContext !== "meetingStage" ||
        ["/meeting/", "/meeting"].includes(location.pathname)
      ) {
        setFrameContext(context.frameContext);
      }
    });
  }, [location]);

  useEffect(() => {
    if (frameContext) {
      navigate(`${meetingUrlBase}/${frameContext}`);
    }
  }, [frameContext, navigate]);

  return (
    <MoodsProvider>
      <MeetingLiveShareProvider>
        <MeetingSocketProvider>
          <Outlet />
        </MeetingSocketProvider>
      </MeetingLiveShareProvider>
    </MoodsProvider>
  );
};
