import * as React from "react";
import { useContext, useEffect, useReducer } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Outlet, Route, useNavigate } from "react-router";
import { endpoints } from "../../common/constants";
import {
  fetchDataActionAxios,
  fetchDataReducer,
  initialState,
} from "../../utils/reducer";
import { AppInsightsContext } from "../contexts/AppInsightsContext";
import { CameraEnableDialog } from "../../widgets/CameraEnablePopup/CameraEnableDialog";
import {
  parseEntityId,
  TeamsLinkContextType,
} from "../../common/utils/deepLinkContextUtils";
import { MoodsProvider } from "./api/moods/MoodsProvider";

const IframePage = React.lazy(() => import("./pages/IframePage"));
const SoundPlayPage = React.lazy(
  () => import("./pages/SoundPlayPage/SoundPlayPage")
);
const MetaOsVimeoPage = React.lazy(() => import("./pages/MetaOsVimeoPage"));
const FinalPage = React.lazy(() => import("./pages/FinalPage"));
const StartPage = React.lazy(() => import("./pages/StartPage/StartPage"));

const Layout = () => {
  const navigate = useNavigate();
  const appInsights = useContext(AppInsightsContext);

  useEffect(() => {
    appInsights?.trackPageView({ name: "Self Paced Tab" });
  }, [appInsights]);

  const [, dispatch] = useReducer(fetchDataReducer<{}>(), initialState);

  useEffect(() => {
    if (appInsights && microsoftTeams) {
      microsoftTeams.getContext((context: microsoftTeams.Context) => {
        if (context.subEntityId) {
          const obj = parseEntityId(context.subEntityId);

          if (!obj) {
            return;
          }

          if (obj.source) {
            appInsights.trackEvent({
              name: `Breakthru Experience ${obj.source}`,
              properties: { type: obj.value },
            });
          }

          if (obj.type === TeamsLinkContextType.acceptChallenge) {
            fetchDataActionAxios(dispatch, {
              url: endpoints.acceptChallenge(obj.value),
              method: "POST",
            });
          }

          if (obj.type === TeamsLinkContextType.surpriseMe) {
            navigate(`/tab/surpriseMe`);
          }

          if (obj.type !== TeamsLinkContextType.acceptChallenge && obj.value) {
            navigate(`/tab/iframe/${obj.value}`);
          }
        }
      });
    }
  }, [navigate, appInsights]);

  return (
    <MoodsProvider>
      <CameraEnableDialog />
      <Outlet />
    </MoodsProvider>
  );
};

export const PlayPageRouter = (
  <Route path="/tab" element={<Layout />}>
    <Route path="surpriseMe" element={<IframePage />} />
    <Route path="sounds" element={<SoundPlayPage />} />
    <Route path="iframe/:title" element={<IframePage />} />
    <Route path="vimeo-meta-os/:title" element={<MetaOsVimeoPage />} />
    <Route path="final/:title" element={<FinalPage />} />
    <Route index element={<StartPage renderSoundButtons />} />
  </Route>
);
