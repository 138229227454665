import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { IUser } from "../../common/types/IUser";

export interface IAuthContextValue {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
  authorized: boolean;
  loading: boolean;
  showErrorModal: boolean;
  signInMicrosoft: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContextValue | null>(null);

export const useAuthContext = (): IAuthContextValue => {
  const value = useContext(AuthContext);

  if (value === null) {
    throw new Error("No provider for AuthContext");
  }

  return value;
};
