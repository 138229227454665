import * as React from "react";
import { Mood } from "../types/Mood";
import { getDemoUrl } from "./demo";

export interface IBaseMood {
  color: string;
  mood: Mood;
}

export interface ISanityMood {
  mood: Mood;
  title: string;
  url: string;
  isSurpriseMeType?: boolean;
}

export type IMood = IBaseMood & ISanityMood;

export const moods: IBaseMood[] = [
  {
    color: "#fbac00",
    mood: "joyful" as Mood,
  },
  {
    color: "#ff6e00",
    mood: "confident" as Mood,
  },
  {
    color: "#008f00",
    mood: "centered" as Mood,
  },
  {
    color: "#006dff",
    mood: "energized" as Mood,
  },
  {
    color: "#85CEF2",
    mood: "breathe" as Mood,
  },
];

// mood: "joyful"
// title: "Joyful #1"
// url: "https://go.breakthru.me/joyful"

export const exampleApiResponse: ISanityMood[] = [
  {
    mood: "energized" as Mood,
    title: "Energized (v1)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/energized`,
  },
  {
    mood: "centered" as Mood,
    title: "Centered (v1)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/centered`,
  },
  {
    mood: "centered" as Mood,
    title: "Centered (v2)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/centered_v2`,
  },
  {
    mood: "confident" as Mood,
    title: "Confident (v1)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/confident`,
  },
  {
    mood: "joyful" as Mood,
    title: "Joyful (v1) (velocity)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/joyful`,
  },
  {
    mood: "confident" as Mood,
    title: "Confident (v2)",
    url: `${process.env.REACT_APP_WEBCAM_URL}/confident_v2`,
  },
];

export const getProcessEnvDomainUrl = (responseMood: ISanityMood) => {
  return (
    process.env.REACT_APP_WEBCAM_URL +
    "/" +
    responseMood.url.split("https://go.breakthru.me/")[1]
  );
};

export const mergeMoods = (activeMoods: ISanityMood[]): IMood[] => {
  return moods.reduce((acc, mood) => {
    const foundVariants = activeMoods.filter((m) => m.mood === mood.mood);
    const randomVariant =
      foundVariants[Math.floor(Math.random() * foundVariants.length)];

    if (randomVariant) {
      const url = getProcessEnvDomainUrl(randomVariant);
      const demoUrl = getDemoUrl(mood.mood);
      return [
        ...acc,
        { color: mood.color, ...randomVariant, url: demoUrl ? demoUrl : url },
      ];
    } else {
      return acc;
    }
  }, [] as IMood[]);
};

export const MoodsContext = React.createContext<IMood[]>([]);
