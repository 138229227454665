import styled from "styled-components";

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #5a569c;
`;

export const Text = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

export const Image = styled.img`
  max-width: 40px;
  margin-top: 16px;
`;
