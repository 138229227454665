import * as React from "react";

export const getQueryMarketPlaceToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  if (token) {
    return token;
  }
  return null;
};

export const getMarketPlaceToken = () => {
  return localStorage.getItem("marketPlaceToken");
};

export const setMarketPlaceToken = (token: string | null) => {
  if (token) {
    return localStorage.setItem("marketPlaceToken", token);
  } else {
    return localStorage.removeItem("marketPlaceToken");
  }
};

export const AccessTokenContext = React.createContext<string | null>(null);
