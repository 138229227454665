import * as React from "react";
import Button from "@mui/material/Button";
import { default as MuiDialog } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useEffect } from "react";
import cn from "classnames";
import type { Breakpoint, SxProps, Theme } from "@mui/material";

interface Props {
  readonly onConfirm?: () => void | Promise<void>;
  readonly maxWidth?: false | Breakpoint | undefined;
  readonly fullWidth?: boolean | undefined;
  readonly sx?: SxProps<Theme> | undefined;
  readonly header?: string | React.ReactElement;
  readonly content?: string | React.ReactElement;
  readonly trigger?: string | React.ReactElement;
  readonly confirmButton?: string | React.ReactElement;
  readonly confirmButtonDisabled?: boolean;
  readonly cancelButton?: string | React.ReactElement;
  readonly className?: string;
  readonly open?: boolean;
  readonly keepMounted?: boolean;
  readonly onOpen?: () => void;
  readonly onCancel?: () => void;
  readonly isModal?: boolean;
}

export const Dialog: FC<Props> = ({
  onConfirm,
  fullWidth,
  trigger,
  header,
  confirmButton,
  confirmButtonDisabled,
  cancelButton,
  content,
  className,
  keepMounted,
  open: openProp,
  onOpen,
  onCancel,
  isModal,
  sx,
  maxWidth,
}) => {
  const [open, setOpen] = React.useState(!!openProp);

  const handleClickOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  const handleClose = () => {
    setOpen(false);
    onCancel?.();
  };

  useEffect(() => {
    setOpen(!!openProp);
  }, [openProp]);

  return (
    <>
      {trigger &&
        (typeof trigger === "string" ? (
          <Button variant="outlined" onClick={handleClickOpen}>
            {trigger}
          </Button>
        ) : (
          <span role="button" onClick={handleClickOpen}>
            {trigger}
          </span>
        ))}
      <MuiDialog
        fullWidth={fullWidth}
        sx={sx}
        maxWidth={maxWidth}
        className={cn(className)}
        open={open}
        keepMounted={keepMounted}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {header && <DialogTitle>{header}</DialogTitle>}
        {isModal && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={() => ({
              position: "absolute",
              right: 8,
              top: 8,
            })}
          >
            <CloseIcon />
          </IconButton>
        )}
        {content && typeof content === "string" ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {content}
            </DialogContentText>
          </DialogContent>
        ) : (
          content
        )}
        {(cancelButton || confirmButton) && (
          <DialogActions>
            {cancelButton &&
              (typeof cancelButton === "string" ? (
                <Button variant="outlined" onClick={handleClose}>
                  {cancelButton}
                </Button>
              ) : (
                cancelButton
              ))}
            {confirmButton &&
              (typeof confirmButton === "string" ? (
                <Button
                  variant="outlined"
                  disabled={confirmButtonDisabled}
                  onClick={async () => {
                    await onConfirm?.();
                    handleClose();
                  }}
                >
                  {confirmButton}
                </Button>
              ) : (
                confirmButton
              ))}
          </DialogActions>
        )}
      </MuiDialog>
    </>
  );
};
