import { CameraEnableDialogContent } from "./CameraEnableDialogContent";
import { CameraNotReadableDialogContent } from "./CameraNotReadableDialogContent";
import { CameraNotFoundDialogContent } from "./CameraNotFoundDialogContent";
import { CameraNotReadableSAPDialogContent } from "./CameraNotReadableSAPDialogContent";
import { CameraEnableWebDialogContent } from "./CameraEnableWebDialogContent";
import type { JSX } from "react";

export enum CameraError {
  CameraNotReadable = "CameraNotReadable",
  CameraNotReadableSAP = "CameraNotReadableSAP",
  CameraNotFound = "CamerasNotFound",
  CameraDisabled = "CameraDisabled",
  CameraDisabledWeb = "CameraDisabledWeb",
}

export const CameraErrorsMap = {
  [CameraError.CameraNotReadable]:
    "NotReadableError: Could not start video source",
  [CameraError.CameraNotFound]: "No cameras found",
  [CameraError.CameraDisabled]: "NotAllowedError: Permission denied",
};

export type CameraEnableDialogContentType = ({
  onClose,
}: {
  onClose: () => void;
}) => JSX.Element;

export const CameraErrorsMapComponents: Record<
  CameraError,
  CameraEnableDialogContentType
> = {
  [CameraError.CameraNotReadable]: CameraNotReadableDialogContent,
  [CameraError.CameraNotReadableSAP]: CameraNotReadableSAPDialogContent,
  [CameraError.CameraNotFound]: CameraNotFoundDialogContent,
  [CameraError.CameraDisabled]: CameraEnableDialogContent,
  [CameraError.CameraDisabledWeb]: CameraEnableWebDialogContent,
};

export const mapCameraError = (
  errorText: string,
  tenantDomain?: string,
  isTeams?: boolean
) => {
  const error = Object.keys(CameraErrorsMap).find(
    (key) => CameraErrorsMap[key] === errorText
  );

  if (error === CameraError.CameraDisabled && !isTeams) {
    return CameraError.CameraDisabledWeb;
  }

  if (error === CameraError.CameraNotReadable && tenantDomain === "sap.com") {
    return CameraError.CameraNotReadableSAP;
  }

  if (error) {
    return error as CameraError;
  }

  return null;
};
