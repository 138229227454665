import { createContext, useContext } from "react";

export interface IMeetingLiveShareContextValue {
  joined: boolean;
  setMood: (mood: string) => void;
  users: Array<{
    userId: string;
    mood: string;
    name: string;
    connected: boolean;
  }>;
  currentUser: { userId?: string; meetingId?: string };
}

export const MeetingLiveShareContext = createContext<IMeetingLiveShareContextValue | null>(
  null
);

export const useMeetingLiveShareContext = (): IMeetingLiveShareContextValue => {
  const value = useContext(MeetingLiveShareContext);

  if (value === null) {
    throw new Error("No provider for MeetingLiveShareContext");
  }

  return value;
};
