import { createContext, useContext, useEffect, useState } from "react";
import { AppInsightsContext } from "../../teamsPage/contexts/AppInsightsContext";

export interface IWelcomeGuideContextValue {
  stepMood: number;
  step: number;
  showGuide: boolean;
  setViewed: (value: boolean) => void;
}

export const WelcomeGuideContext = createContext<IWelcomeGuideContextValue | null>(
  null
);

export const useWelcomeGuideContext = (): IWelcomeGuideContextValue => {
  const value = useContext(WelcomeGuideContext);
  const appInsights = useContext(AppInsightsContext);
  const [isFirst, setIsFirst] = useState(false);

  useEffect(() => {
    if (!isFirst && appInsights && value?.showGuide) {
      setIsFirst(true);
      appInsights.trackEvent({
        name: "User sees First play experience guide",
      });
    }
  }, [appInsights, isFirst, value]);

  if (value === null) {
    throw new Error("No provider for WelcomeGuideContext");
  }

  return value;
};
