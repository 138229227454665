import * as React from "react";
import { Layout } from "./components/Layout";
import { Route } from "react-router";
import { MsalProviderLayout } from "../ui/MsalProviderLayout";
import { msalConfig } from "./utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

const ActivationPage = React.lazy(
  () => import("./pages/activation/ActivationPage")
);
const SubscriptionsPage = React.lazy(
  () => import("./pages/subscriptions/SubscriptionsPage")
);

export const LandingPageRouter = (
  <Route
    path="/landing"
    element={
      <MsalProviderLayout msalInstance={msalInstance}>
        <Layout />
      </MsalProviderLayout>
    }
  >
    <Route path="subscriptions" element={<SubscriptionsPage />} />
    <Route index element={<ActivationPage />} />
  </Route>
);
