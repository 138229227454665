import { endpoints } from "../../common/constants";
import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import { useContext, useMemo } from "react";
import { IAuthTeamsUserBody } from "../../common/types/IAuth";
import { AppInsightsContext } from "../../teamsPage/contexts/AppInsightsContext";

export const useAuthService = () => {
  const appInsights = useContext(AppInsightsContext);

  return useMemo(
    () => ({
      authTeamsWithSSO: async () => {
        try {
          const msToken = await microsoftTeams.authentication.getAuthToken({
            silent: true,
          });
          appInsights?.trackEvent({
            name: "getAuthToken - success - silent: true",
          });
          return axios.get(endpoints.authAzure, {
            headers: {
              Authorization: `Bearer ${msToken}`,
            },
          });
        } catch (exception) {
          appInsights?.trackException({ exception });
          throw exception;
        }
      },
      authTeams: async (data: IAuthTeamsUserBody) => {
        if (!data.email) {
          throw new Error("email for user is not found in teams context");
        }
        return axios.post(endpoints.authTeams, data);
      },
      refresh: async () => {
        return axios.get(endpoints.refresh);
      },
      isTokenExpired: (tokenName: "accessToken" | "refreshToken") => {
        const expiresAtCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith(`${tokenName}ExpiresAt=`))
          ?.split("=")[1];

        if (!expiresAtCookie) {
          return true;
        }

        return +expiresAtCookie < Date.now();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
