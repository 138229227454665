import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { IReducerState } from "../../utils/reducer";
import { ISubscriptionResponse } from "../../common/types/IMicrosoftStoreSubscription";

export interface ISubscriptionContextValue {
  isClosedForFreeCountry: boolean;
  setIsClosedForFreeCountry: Dispatch<SetStateAction<boolean>>;
  subscriptionState: IReducerState<ISubscriptionResponse>;
  getCurrentSubscription: () => Promise<void>;
}

export const SubscriptionContext = createContext<ISubscriptionContextValue | null>(
  null
);

export const useSubscriptionContext = (): ISubscriptionContextValue => {
  const value = useContext(SubscriptionContext);

  if (value === null) {
    throw new Error("No provider for SubscriptionContext");
  }

  return value;
};
