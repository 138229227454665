import * as React from "react";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import { HowToRedirect } from "./teamsPage/howTo/HowToRedirect";
import { FullScreenLoader } from "./ui/FullScreenLoader";
import { createRoot } from "react-dom/client";
import { TeamsLayout } from "./ui/TeamsLayout";
import { TeamsPageRouter } from "./teamsPage/TeamsPageRouter";
import { WalmartDemoPageRouter } from "./pages/walmartDemo/WalmartDemoPageRouter";
import { WalmartSupportPageRouter } from "./pages/walmartSupport/WalmartSupportPageRouter";
import { LandingPageRouter } from "./landingPage/LandingPageRouter";
import { DemoPageRouter } from "./pages/demo/DemoPageRouter";

import "./teamsPage/assets/main.css";
import "./teamsPage/assets/app.css";
import "./teamsPage/assets/tailwind.css";

const container = document.getElementById("app")!;
const root = createRoot(container);

const TeamsPricesPage = React.lazy(
  () => import("./pages/stripe/StripePricesPage")
);
const SuccessPaymentPage = React.lazy(
  () => import("./pages/stripe/SuccessPaymentPage")
);
const FailedPaymentPage = React.lazy(
  () => import("./pages/stripe/FailedPaymentPage")
);

root.render(
  <BrowserRouter>
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route path="/payment">
          <Route path="success" element={<SuccessPaymentPage />} />
          <Route path="failed" element={<FailedPaymentPage />} />
          <Route element={<TeamsLayout />}>
            <Route path="prices" element={<TeamsPricesPage />} />
          </Route>
        </Route>
        <Route path="/joyful" element={<HowToRedirect />} />
        <Route path="/confident" element={<HowToRedirect />} />
        <Route path="/energized" element={<HowToRedirect />} />
        <Route path="/centered" element={<HowToRedirect />} />
        <Route path="/share/*" element={<HowToRedirect />} />
        {LandingPageRouter}
        {DemoPageRouter}
        {WalmartSupportPageRouter}
        {WalmartDemoPageRouter}
        {TeamsPageRouter}
      </Routes>
    </Suspense>
  </BrowserRouter>
);
