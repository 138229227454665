import { createContext, useContext } from "react";
import { IMood, ISanityMood } from "../../../../common/services/moods";

export type PrioritiesType = Record<string, { title?: string; order: number }>;
export const MoodsContext = createContext<{
  filteredMoods: IMood[];
  moods: ISanityMood[];
  surpriseMeMood?: ISanityMood;
  setPrioritiesForNextScene: (mood: string) => void;
  setOrderForSurpriseMe: () => void;
} | null>(null);

export const useMoodsContext = () => {
  const value = useContext(MoodsContext);

  if (value === null) {
    throw new Error("No provider for MoodsContext");
  }

  return value;
};
