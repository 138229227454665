import * as React from "react";
import { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { AppInsightsContext } from "../../../teamsPage/contexts/AppInsightsContext";
import { TeamsThemeProvider } from "../../../teamsPage/contexts/TeamsThemeProvider";
import { MoodsProvider } from "../../../teamsPage/play/api/moods/MoodsProvider";

export const Layout: React.FC<{ readonly title: string }> = ({ title }) => {
  const appInsights = useContext(AppInsightsContext);

  useEffect(() => {
    appInsights?.trackPageView({ name: `${title} Play Page` });
  }, [appInsights, title]);

  return (
    <TeamsThemeProvider>
      <MoodsProvider>
        <Outlet />
      </MoodsProvider>
    </TeamsThemeProvider>
  );
};
