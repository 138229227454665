import styled, { keyframes } from "styled-components";

const scaleAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100vh);
  }
`;

export const Emoji = styled.img`
  position: fixed;
  width: 64px;
  bottom: -64px;
  left: 24px;
  z-index: 1000;
  animation: ${scaleAnimation} 20s ease forwards;
`;

export const FinalEmoji = styled.img`
  position: fixed;
  width: 64px;
  bottom: -64px;
  left: 24px;
  z-index: 1000;
  animation: ${scaleAnimation} 10s ease forwards;

  ${(props) =>
    props.theme.delay ? `animation-delay: ${props.theme.delay}s;` : ""}
  ${(props) => (props.theme.pos ? `left: ${24 + props.theme.pos * 64}px;` : "")}
`;

export const emojisImagesMap = {
  breathe: `${process.env.REACT_APP_ASSETS_HOST}/images/gifs/emoji_butterfly_morpho_2.gif`,
  confident: `${process.env.REACT_APP_ASSETS_HOST}/images/gifs/emoji_butterfly_monarch_2.gif`,
  joyful: `${process.env.REACT_APP_ASSETS_HOST}/images/gifs/emoji_butterfly_yellow.gif`,
  energized: `${process.env.REACT_APP_ASSETS_HOST}/images/gifs/emoji_butterfly_morpho_2.gif`,
  centered: `${process.env.REACT_APP_ASSETS_HOST}/images/gifs/emoji_butterfly_green.gif`,
};
