import * as React from "react";
import Button from "@mui/material/Button";
import { iconUrl } from "./CameraEnableDialog.styles";
import { Title, Image, Text } from "./CameraEnableDialog.styles";

export const CameraNotReadableDialogContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  // const locale = useLocale();
  return (
    <div className="text-center relative p-4">
      <Image className="mx-auto" src={iconUrl} alt="" />
      <Title>Camera access issue</Title>
      <Text>
        <div>Follow the instruction:</div>
        <ul className="text-left list-disc">
          <li>
            If your camera is used by another Application. Please turn it off
            and reload <b>Breakthru</b>.
          </li>
          <li>
            Please run your computer system update, and update your webcam
            drivers. You will then have full access to <b>Breakthru</b>.
          </li>
        </ul>
      </Text>
      <Button variant="outlined" onClick={onClose} className="mt-5">
        Close
      </Button>
    </div>
  );
};
