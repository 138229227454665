import * as React from "react";
import { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import { loginRequest } from "../utils/authConfig";
import { GlobalAnimationStyles, theme } from "../../ui/globalStyles";
import { GlobalStyle } from "../../ui/GlobalStyle";
import styled from "styled-components";
import Header from "./Header";
import { Outlet, useLocation, useNavigate } from "react-router";
import {
  AccessTokenContext,
  getQueryMarketPlaceToken,
  setMarketPlaceToken,
} from "../utils/utils";
import Text from "@mui/material/Typography";

const Container = styled.div`
  max-width: 800px;
`;

const TokenContainer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const marketPlaceToken = getQueryMarketPlaceToken();
    if (marketPlaceToken) {
      setMarketPlaceToken(marketPlaceToken);
    } else {
      navigate("/landing/subscriptions");
    }
  }, [navigate]);

  return <></>;
};

export const Layout = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    async function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      return await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      } as any);
    }

    if (accounts[0]) {
      RequestProfileData().then((res) => {
        setAccessToken(res.accessToken);
      });
    }
  }, [instance, accounts]);

  return (
    <>
      <GlobalAnimationStyles />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header />
        <div className="container mx-auto mt-10 mb-20">
          <Container className="mx-auto">
            <AccessTokenContext.Provider value={accessToken}>
              <AuthenticatedTemplate>
                <TokenContainer />
                <Outlet />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Text variant="h5" className="title text-center my-8">
                  Please sign-in to&nbsp;
                  <Text component="span">
                    {location.pathname === "/subscriptions"
                      ? `see your subscriptions.`
                      : `activate subscription.`}
                  </Text>
                </Text>
              </UnauthenticatedTemplate>
            </AccessTokenContext.Provider>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};
