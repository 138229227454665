import { createContext, useContext } from "react";
import { EnterpriseSharingState } from "./EnterpriseSharingProvider";

export interface IEnterpriseSharingContext {
  setUserShared: () => void;
  state: EnterpriseSharingState;
}

export const EnterpriseSharingContext = createContext<IEnterpriseSharingContext | null>(
  null
);

export const useEnterpriseSharingContext = (): IEnterpriseSharingContext => {
  const value = useContext(EnterpriseSharingContext);

  if (value === null) {
    throw new Error("No provider for WelcomeGuideContext");
  }

  return value;
};
