import { fontFamily } from "./constants";
import { createTheme } from "@mui/material/styles";
import { createGlobalStyle } from "styled-components";

export const GlobalAnimationStyles = createGlobalStyle`
  body {
    color: #515151;
    background-color: #fbf3e5 !important;
    font-family: ${fontFamily};
  }
`;

export const themeOptions = {
  typography: {
    fontFamily,
  },
  palette: {
    text: {
      primary: "#515151",
    },
    primary: {
      main: "#4c53bc",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "96px",
        },
        outlined: {
          padding: "6px 16px 4px",
          borderColor: "rgb(209, 209, 209)",
          color: "#515151",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: "125px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#515151",
        },
        h1: {
          fontWeight: 300,
          fontSize: "2.5rem",
          marginBottom: "5rem",
        },
        h2: {
          fontWeight: 300,
          fontSize: "2rem",
          marginBottom: "5rem",
        },
        h5: {
          fontSize: "0.75rem",
        },
      },
    },
  },
} as const;

export const theme = createTheme(themeOptions);

export const darkTheme = createTheme({
  ...themeOptions,
  palette: {
    primary: {
      main: "#4c53bc",
    },
    mode: "dark",
  },
  components: {
    ...themeOptions.components,
    MuiButton: {
      ...themeOptions.components.MuiButton,
      styleOverrides: {
        ...themeOptions.components.MuiButton.styleOverrides,
        outlined: {
          padding:
            themeOptions.components.MuiButton.styleOverrides.outlined.padding,
          color: "#F8F9FA",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
        h1: themeOptions.components.MuiTypography.styleOverrides.h1,
        h2: themeOptions.components.MuiTypography.styleOverrides.h2,
        h5: themeOptions.components.MuiTypography.styleOverrides.h5,
      },
    },
  },
});
