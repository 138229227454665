import * as React from "react";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import { AuthContext } from "./AuthContext";
import { axiosInstance } from "../../utils/axiosInstance";
import { IUser } from "../../common/types/IUser";
import { endpoints } from "../../common/constants";
import { useAuthService } from "./AuthService";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../landingPage/utils/authConfig";
import { TeamsContext } from "../../teamsPage/contexts/TeamsContext";
import { FullScreenLoader } from "../../ui/FullScreenLoader";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useLocation } from "react-router";

const REACT_APP_SSO_ACTIVE = process.env.REACT_APP_SSO_ACTIVE === "true";
// global variables used for blocking parallel refreshing or auth by /auth/teams
let refreshPromise: Promise<unknown> | undefined;
let refreshFailedPromise: Promise<unknown> | undefined;

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authService = useAuthService();
  const teamsContext = useContext(TeamsContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useLocalStorage<IUser | null>("msUserStore", null);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { instance } = useMsal();

  const getCurrentUser = async () => {
    setLoading(true);
    const res = await axiosInstance.get<IUser>(endpoints.currentUser);
    if (res) {
      setUser(res.data);
      setAuthorized(true);
      setLoading(false);
    } else {
      setUser(null);
      setAuthorized(false);
      setLoading(false);
    }
  };

  const authUser = (user: microsoftTeams.app.Context["user"]) => {
    setLoading(true);

    authService
      .authTeamsWithSSO()
      .then(() => getCurrentUser())
      .catch(() => {
        if (REACT_APP_SSO_ACTIVE) {
          setShowErrorModal(true);
          setLoading(false);
        } else {
          if (user) {
            authService
              .authTeams({
                msUserId: user.id,
                email: user?.loginHint as string,
                msTenantId: user.tenant?.id as string,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: Intl.NumberFormat().resolvedOptions().locale,
              })
              .then(() => getCurrentUser());
          }
        }
      });
  };

  const signInMicrosoft = useCallback(async () => {
    const res = await instance.loginPopup(loginRequest);

    await axios.get(endpoints.authMsSignIn, {
      headers: {
        Authorization: `Bearer ${res.accessToken}`,
      },
    });

    await getCurrentUser();
    setShowErrorModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(
    () => {
      const refreshExpiredHandling = async (
        config: InternalAxiosRequestConfig
      ) => {
        if (!teamsContext) {
          // for web
          setAuthorized(false);
          setUser(null);
          navigate(`/registration${location.search}`);
          setLoading(false);
        } else {
          // fot teams
          try {
            await authService.authTeamsWithSSO();
          } catch (e) {
            if (REACT_APP_SSO_ACTIVE) {
              setShowErrorModal(true);
            } else {
              await authService.authTeams({
                msUserId: teamsContext.userObjectId as string,
                email: teamsContext.userPrincipalName as string,
                msTenantId: teamsContext.tid as string,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: Intl.NumberFormat().resolvedOptions().locale,
              });
            }
            setLoading(false);
          }

          if (config) {
            return config;
          }
        }
      };

      const responseInterceptor = axiosInstance.interceptors.response.use(
        (config) => {
          return config;
        },
        async (error: AxiosError) => {
          if (error.response?.status !== 401) {
            throw error;
          }

          try {
            if (authService.isTokenExpired("refreshToken")) {
              throw new Error("Refresh token expired");
            }

            refreshPromise = refreshPromise ?? authService.refresh();
            await refreshPromise;
            refreshPromise = undefined;
            if (error.config) {
              return axios.request(error.config);
            }
          } catch (e) {
            if (error.config) {
              refreshFailedPromise =
                refreshFailedPromise ?? refreshExpiredHandling(error.config);
              const result = await refreshFailedPromise;
              refreshFailedPromise = undefined;
              if (error.config && result) {
                return axios.request(error.config);
              } else {
              }
            }
          }
        }
      );

      const requestInterceptor = axiosInstance.interceptors.request.use(
        async (config) => {
          if (
            !authService.isTokenExpired("accessToken") &&
            !authService.isTokenExpired("refreshToken")
          ) {
            console.log("all tokens ok");
          }

          if (
            authService.isTokenExpired("accessToken") &&
            !authService.isTokenExpired("refreshToken")
          ) {
            console.log("accessToken expired but refreshing");
            refreshPromise = refreshPromise ?? authService.refresh();
            await refreshPromise;
            refreshPromise = undefined;
          }

          if (
            authService.isTokenExpired("accessToken") &&
            authService.isTokenExpired("refreshToken")
          ) {
            console.log("refreshToken expired");
            refreshFailedPromise =
              refreshFailedPromise ?? refreshExpiredHandling(config);
            const result = await refreshFailedPromise;
            refreshFailedPromise = undefined;

            if (result) {
              return config;
            } else {
              return Promise.reject(
                new axios.Cancel("Request canceled, refreshToken expired.")
              );
            }
          }

          return config;
        },
        async (error: AxiosError) => {}
      );

      return () => {
        axiosInstance.interceptors.response.eject(responseInterceptor);
        axiosInstance.interceptors.request.eject(requestInterceptor);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location, teamsContext]
  );

  useEffect(() => {
    if (!user) {
      getCurrentUser();
    } else {
      setLoading(false);
      setAuthorized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      microsoftTeams.app.getContext().then((c) => {
        if (c.user && c.user?.loginHint !== user.email) {
          if (!c.user?.loginHint || !c.user.tenant?.id) {
            throw new Error(
              "c.user?.loginHint or c.user.tenant?.id is not found"
            );
          }

          authUser(c.user);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const value = useMemo(() => {
    return {
      user,
      setUser,
      authorized,
      loading,
      showErrorModal,
      signInMicrosoft,
    };
  }, [user, setUser, authorized, loading, showErrorModal, signInMicrosoft]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? <FullScreenLoader /> : children}
    </AuthContext.Provider>
  );
};
