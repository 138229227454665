import * as React from "react";
import { Title } from "./AuthErrorModal.styles";
import { useAuthContext } from "../../processes/auth/AuthContext";
import { useGetTrackEvent } from "../../utils/appInsights/useGetTrackEvent";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { SignInWithMicrosoftButton } from "../../ui/buttons/SignInWithMicrosoftButton";
import { Dialog } from "../../ui/Dialog/Dialog";

const REACT_APP_SSO_ACTIVE = process.env.REACT_APP_SSO_ACTIVE === "true";

export const AuthErrorModal = () => {
  const auth = useAuthContext();
  const trackEvent = useGetTrackEvent();
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (auth.showErrorModal) {
      trackEvent({ name: "User sees SSO failure message" });
    }
  }, [trackEvent, auth.showErrorModal]);

  const closeHandler = () => {
    setClosed(true);
  };

  const content = (
    <div className={"text-center relative p-4"}>
      <Title>Authorisation</Title>

      <div className={"flex justify-center"}>
        <SignInWithMicrosoftButton />
      </div>

      <Button variant="outlined" onClick={closeHandler} className="mt-5">
        Close
      </Button>
    </div>
  );

  const open = closed ? false : auth.showErrorModal;

  if (!REACT_APP_SSO_ACTIVE) {
    return null;
  }

  return <Dialog open={open} content={content} onCancel={closeHandler} />;
};
