import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { mediaBreakpoints, colors, fontFamily } from "./constants";
import { useTheme } from "@mui/material/styles";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${fontFamily};
    transition: 0.2s ease background;
  }
`;

export const ButtonWrapper = styled.span`
  font-family: ${fontFamily};
  font-weight: 600;
`;

export const TitleWrapper = styled.div`
  font-family: ${fontFamily};
  font-size: 20px;
  line-height: 1;
  margin-bottom: 60px;
  text-align: center;
  margin-top: 100px;

  ${mediaBreakpoints.mobile} {
    margin-top: 50px;
  }
`;

const Root = styled.div`
  color: ${(props) =>
    props.theme.themeType && props.theme.themeType !== "default"
      ? colors.white
      : colors.default};
`;

export const RootWithTheme: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const theme = useTheme();
  return <Root theme={theme}>{children}</Root>;
};
