import * as React from "react";
import styled from "styled-components";
import { colors, fontFamily } from "../../../ui/constants";

const TitleBase = styled.h2`
  font-family: ${fontFamily};
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => (props.color ? colors[props.color] : props.theme.color)};
  padding-bottom: 7px;
`;

const LineHelper = styled.span`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 3px;
    width: 100%;
    background: ${(props) =>
      props.color ? colors[props.color] : props.theme.color};
  }
`;

interface ITitleProps {
  children: React.ReactNode;
  color?: "default" | "white";
}

const Title = ({ children, color }: ITitleProps) => {
  return (
    <TitleBase color={color}>
      <LineHelper color={color}>{children}</LineHelper>
    </TitleBase>
  );
};

export default Title;
