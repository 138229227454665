import * as React from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";

const IframePage = React.lazy(() => import("./pages/IframePage"));
const StartPage = React.lazy(() => import("./pages/StartPage"));
const BreatheVimeoPage = React.lazy(
  () => import("./pages/BreatheVimeoPage/BreatheVimeoPage")
);

export const DemoPageRouter = (
  <Route path="/demo" element={<Layout title={`Demo`} />}>
    <Route path="iframe/:title" element={<IframePage />} />
    <Route path="vimeo-breathe" element={<BreatheVimeoPage />} />
    <Route index element={<StartPage />} />
  </Route>
);
