import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

export function useTeamsContextInit() {
  const [context, setContext] = useState<microsoftTeams.Context>();

  useEffect(() => {
    microsoftTeams.getContext((c: microsoftTeams.Context) => {
      console.log("[Teams context]", c);
      setContext(c);
      microsoftTeams.app.notifySuccess();
    });
  }, []);

  return context;
}
