import { useContext } from "react";
import { TeamsContext } from "../../teamsPage/contexts/TeamsContext";
import { parseLocale } from "../../common/utils/translations/parseLocale";

export const useLocale = () => {
  const teamsContext = useContext(TeamsContext);

  return parseLocale(
    teamsContext?.locale ?? window?.navigator?.language ?? "en"
  );
};
