import { Outlet } from "react-router";
import * as React from "react";
import { FC } from "react";
import { useMeetingLiveShareContext } from "../../../processes/meetingLiveShare/MeetingLiveShareContext";
import { FullScreenLoader } from "../../../ui/FullScreenLoader";
import { FlyingEmojis } from "../components/FlyingEmojis/FlyingEmojis";

export const MeetingStageLayout: FC = () => {
  const { joined } = useMeetingLiveShareContext();

  if (!joined) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <FlyingEmojis />
      <Outlet />
    </>
  );
};
