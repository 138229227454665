export type DeepLinkSource =
  | "from Reminder Card"
  | "from Meeting Card"
  | "from Gift Card"
  | "from Welcome Gift Card"
  | "from Welcome Card"
  | "from New Joyful Card"
  | "from New Confident Card"
  | "from New Centered Card"
  | "from Challenge Reminder Play Button"
  | "from History Page"
  | "from First Time Play Achievement"
  | "from Mood Achievement"
  | "from Meeting Panel"
  | "from Achievement Meeting Panel"
  | "from Shared Achievement Gift"
  | "from Announce Card"
  | "from Back to work card"
  | "from Microsoft Friends card"
  | "from Shared Gift"
  | "from Weekly Insights Card"
  | "from Los Angeles Announcement card";

export enum TeamsLinkContextType {
  acceptChallenge = "acceptChallenge",
  moodExperience = "moodExperience",
  surpriseMe = "surpriseMe",
  breath = "breath",
  default = "default",
}

export const createDefaultLinkContext = (source: DeepLinkSource): string => {
  const subEntityId = `/${source}//${TeamsLinkContextType.default}`;
  return encodeURI(`{"subEntityId": "${subEntityId}"}`);
};

export const createMoodExperienceLinkContext = (
  mood: string,
  source: DeepLinkSource,
  priorityMoodTitle?: string
): string => {
  const subEntityId = `${mood}/${source}/${priorityMoodTitle ?? ""}/${
    TeamsLinkContextType.moodExperience
  }`;
  return encodeURI(`{"subEntityId": "${subEntityId}"}`);
};

export const createBreathLinkContext = (
  mood: string,
  source: DeepLinkSource,
  vimeoUrlParts: [string, string]
): string => {
  const subEntityId = `${mood}/${source}/${vimeoUrlParts.join("+") ?? ""}/${
    TeamsLinkContextType.breath
  }`;
  return encodeURI(`{"subEntityId": "${subEntityId}"}`);
};

export const createChallengeAcceptLinkContext = (
  challengeId: number,
  source: DeepLinkSource
): string => {
  const subEntityId = `${challengeId}/${source}//${TeamsLinkContextType.acceptChallenge}`;
  return encodeURI(`{"subEntityId": "${subEntityId}"}`);
};

export const createSurpriseMeLinkContext = (source: DeepLinkSource): string => {
  const subEntityId = `/${source}//${TeamsLinkContextType.surpriseMe}`;
  return encodeURI(`{"subEntityId": "${subEntityId}"}`);
};

export const parseEntityId = (
  entityId: string
): {
  type: TeamsLinkContextType | null;
  value: string;
  source?: string;
  priorityMoodTitle?: string;
} | null => {
  const parts = entityId.split("/");
  if (parts.length > 1) {
    const [value, source, other, type] = parts;

    return {
      type: type as TeamsLinkContextType,
      value,
      source,
      priorityMoodTitle: other,
    };
  }

  if (entityId) {
    return { type: null, value: entityId };
  }

  return null;
};
