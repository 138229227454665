import * as React from "react";
import { FC, useCallback, useEffect, useMemo } from "react";
import { EnterpriseSharingContext } from "./EnterpriseSharingContext";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { differenceInCalendarDays, startOfDay } from "date-fns";

export type EnterpriseSharingState = {
  times: number;
  sharedAt: string;
};

const ENTERPRISE_SHARING_STATE = "ENTERPRISE_SHARING_STATE";

export const EnterpriseSharingProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useLocalStorage<EnterpriseSharingState>(
    ENTERPRISE_SHARING_STATE,
    { times: 0, sharedAt: "" }
  );

  useEffect(() => {
    // if one month passed since last third share then reset state
    if (
      state.sharedAt &&
      differenceInCalendarDays(
        startOfDay(new Date()),
        startOfDay(new Date(state.sharedAt))
      ) >= 30
    ) {
      setState({ times: 0, sharedAt: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserShared = useCallback(() => {
    const times = state.times + 1;
    setState({ times, sharedAt: "" });
    if (times === 3) {
      setState({ times, sharedAt: new Date().toISOString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.times]);

  const value = useMemo(() => {
    return { state, setUserShared };
  }, [state, setUserShared]);

  return (
    <EnterpriseSharingContext.Provider value={value}>
      {children}
    </EnterpriseSharingContext.Provider>
  );
};
