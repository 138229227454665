import { TeamsThemeProvider } from "../../teamsPage/contexts/TeamsThemeProvider";
import { TeamsContext } from "../../teamsPage/contexts/TeamsContext";
import * as React from "react";
import { useTeamsContextInit } from "../../utils/useTeamsContextInit";
import { useTeamsInit } from "../../utils/useIsTeams";
import { FullScreenLoader } from "../../ui/FullScreenLoader";

// TODO: there is no need to init an app if teams aren't initialized, so we shouldn't use react hooks.
export const TeamsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const teamsInitResult = useTeamsInit();
  const teamsContext = useTeamsContextInit();

  return (
    <TeamsContext.Provider value={teamsContext}>
      {teamsInitResult.loading || (teamsInitResult.isTeams && !teamsContext) ? (
        <FullScreenLoader />
      ) : (
        <TeamsThemeProvider>{children}</TeamsThemeProvider>
      )}
    </TeamsContext.Provider>
  );
};
