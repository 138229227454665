const seattleDemoMoods = {
  confident: "https://go.breakthru.me/conf_v3v4",
  joyful: "https://go.breakthru.me/joyful_09-2",
  centered: "https://go.breakthru.me/centered_07",
  energized: "https://go.breakthru.me/energized_v4",
};

const isDemoVersion = () => {
  if (!window) {
    return false;
  }

  return window.location.pathname.includes("demo");
};

export const getDemoUrl = (mood: string): null | string => {
  if (!isDemoVersion()) {
    return null;
  }

  if (!seattleDemoMoods[mood]) {
    return null;
  }

  return seattleDemoMoods[mood];
};
