export const urls = {
  sanityMoods:
    "https://b8g9nnui.api.sanity.io/v1/data/query/production?query=%2A%5B_type%3D%3D%27scene%27%5D%7B%0A%20%20title%2C%0A%20%20isSurpriseMeType%2C%0A%20%20%27mood%27%3A%20mood-%3Eslug.current%2C%0A%20%20%27url%27%3A%27https%3A%2F%2Fgo.breakthru.me%2F%27%2Bslug.current%0A%7D",
};

export const endpoints = {
  currentUser: "/api/currentUser",
  userShared: "/api/userShared",
  authAzure: "/api/auth/azure",
  authTeams: "/api/auth/teams",
  authGoogle: "/api/auth/google",
  authMsSignIn: "/api/auth/microsoft-sign-in",
  refresh: "/api/auth/refresh",

  moods: "/api/moods",
  usersStatistics: "/api/users-statistics",
  getUsersStatisticsCount: "/api/users-statistics-count",
  reminders: "/api/reminders",
  teamsReminders: "/api/teamsReminders",
  activateSubscription: "/api/activateSubscriptionByToken",
  subscriptions: "/api/subscriptions",
  mandatoryCountriesSubscription: "/api/mandatoryCountriesSubscription",
  stripeSubscription: (id: string) => `/api/stripeSubscriptions/${id}`,
  countries: "/api/countries",
  countryPricing: (id) => `/api/countries/${id}/pricing`,
  subscriptionById: (subscriptionId) => `/api/subscriptions/${subscriptionId}`,
  checkSubscription: "/api/subscription",
  userById: (userId) => `/api/users/${userId}`,
  checkAvailability: "/api/challenge/checkAvailability",
  achievements: "/api/achievements",
  achievementsProgress: "/api/achievements/progress",
  userChallenges: "/api/challenges",
  userTrialDays: "/api/userTrialDays",
  companyRequest: "/api/companyRequest",
  schoolRequest: "/api/schoolRequest",
  whitelistedTenant: "/api/whitelistedTenant",
  companyEnterprise: "/api/companyEnterprise",
  whitelistedUser: "/api/whitelistedUser",
  slackBot: "/api/slack/events",
  acceptChallenge: (challengeId: string | number) =>
    `/api/challenges/${challengeId}/accept`,
  stripeSlackSubscription: (priceId: string) =>
    `/api/stripeSlackSubscription/checkout/${priceId}`,
  subscriptionFillTable: "/api/subscription/fill-table",
  feedback: "/api/feedback",
};
