import { Route } from "react-router";
import * as React from "react";
import { Layout } from "./components/Layout";
import { MeetingStageLayout } from "./components/MeetingStageLayout";

const PreMeeting = React.lazy(() => import("./pages/PreMeeting/PreMeeting"));
const SidePanel = React.lazy(() => import("./pages/SidePanel"));
const Settings = React.lazy(() => import("./pages/Settings"));
const StartPage = React.lazy(() => import("../play/pages/StartPage/StartPage"));
const BreatheVimeoPage = React.lazy(
  () => import("./pages/MeetingStage/BreatheVimeoPage")
);
const VimeoPage = React.lazy(() => import("./pages/MeetingStage/VimeoPage"));
const MeetingStageStatistic = React.lazy(
  () => import("./pages/MeetingStage/MeetingStageStatistic")
);

export const MeetingPageRouter = (
  <Route path="/meeting" element={<Layout />}>
    <Route path="settings" element={<Settings />} />
    <Route path="sidePanel" element={<SidePanel />} />
    <Route path="content" element={<PreMeeting />} />
    <Route path="meetingStage" element={<MeetingStageLayout />}>
      <Route index element={<StartPage />} />
      <Route path={`vimeo/breathe`} element={<BreatheVimeoPage />} />
      <Route path={`vimeo/:title`} element={<VimeoPage />} />
      <Route
        path={`meeting-stage-statistic`}
        element={<MeetingStageStatistic />}
      />
    </Route>
  </Route>
);
