import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const useAppInsightInit = (appInsights: ApplicationInsights) => {
  useEffect(() => {
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((item) => {
      if (item.data) {
        if (!item.data.source) {
          item.data.source = "Web";
        }
      }
    });

    microsoftTeams.getContext((c: microsoftTeams.Context) => {
      const name = c.loginHint || c.userPrincipalName;
      if (name) {
        appInsights.setAuthenticatedUserContext(name);
        appInsights.addTelemetryInitializer((item) => {
          if (item.data) {
            const tenant = name.split("@")[1];
            if (tenant) {
              item.data.tenant = tenant;
              item.data.source = c.hostName ?? "Teams";
            }
          }
        });
      }
    });
  }, [appInsights]);
};
