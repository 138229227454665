import * as React from "react";
import Button from "@mui/material/Button";
import { iconUrl } from "./CameraEnableDialog.styles";
import { Title, Image, Text } from "./CameraEnableDialog.styles";

export const CameraEnableWebDialogContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <div className="text-center relative p-4">
      <Image className="mx-auto" src={iconUrl} alt="" />
      <Title>Enable camera access.</Title>
      <Text>
        For the full breakthru experience, we need to enable camera permissions.
      </Text>
      <Button variant="outlined" onClick={onClose} className="mt-5">
        Close
      </Button>
    </div>
  );
};
