import * as React from "react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../../ui/GlobalStyle";
import { theme as lightTheme, darkTheme } from "../../ui/globalStyles";
import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { themes } from "../../ui/constants";

type ThemeTypes = "dark" | "contrast" | "default";

export const TeamsThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState(lightTheme);
  const [styledTheme, setStyledTheme] = React.useState(themes.default);

  useEffect(() => {
    microsoftTeams.app
      .getContext()
      .then((c: microsoftTeams.app.Context) => {
        microsoftTeams.app.registerOnThemeChangeHandler((t: ThemeTypes) => {
          setTheme(t === "dark" ? darkTheme : lightTheme);
          setStyledTheme(t === "dark" ? themes.dark : themes.default);
        });

        setTheme(
          (c.app.theme as ThemeTypes) === "dark" ? darkTheme : lightTheme
        );
        setStyledTheme(c.app.theme === "dark" ? themes.dark : themes.default);
      })
      .catch();
  }, []);

  return (
    <MUIThemeProvider theme={theme}>
      <ThemeProvider theme={styledTheme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MUIThemeProvider>
  );
};
