import { Reducer } from "react";
import { axiosInstance } from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export interface IReducerState<T> {
  data: T | null;
  fetching: boolean;
  error: Error | null;
}

type ReducerAction<T> =
  | {
      type: "FETCH";
    }
  | {
      type: "SUCCESS";
      payload: T;
    }
  | {
      type: "ERROR";
      payload: Error;
    }
  | {
      type: "SET";
      payload: T | null;
    };

export const initialState = {
  data: null,
  fetching: false,
  error: null,
};

export const fetchDataReducer = <T>(): Reducer<
  IReducerState<T>,
  ReducerAction<T>
> => (state = initialState, action) => {
  switch (action.type) {
    case "FETCH":
      return {
        data: null,
        fetching: true,
        error: null,
      };
    case "SUCCESS":
      return {
        data: action.payload,
        fetching: false,
        error: null,
      };
    case "ERROR":
      return {
        data: null,
        fetching: false,
        error: action.payload,
      };
    case "SET":
      return {
        ...state,
        data: action.payload,
      };
  }

  return state;
};

export const fetchDataAction = <T>(
  dispatch: (v: ReducerAction<T>) => void,
  input: RequestInfo,
  init?: RequestInit
) => {
  dispatch({
    type: "FETCH",
  });
  return fetch(input, init)
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data?.message);
      }
      return data;
    })
    .then((data: T) => {
      dispatch({
        type: "SUCCESS",
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: "ERROR",
        payload: error.message,
      });
      return error;
    });
};

export const fetchDataActionAxios = <T>(
  dispatch: (v: ReducerAction<T>) => void,
  config: AxiosRequestConfig
) => {
  dispatch({
    type: "FETCH",
  });
  return axiosInstance(config)
    .then((response: AxiosResponse<T>) => {
      dispatch({
        type: "SUCCESS",
        payload: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "ERROR",
        payload: error?.response?.data?.message,
      });
      return error;
    });
};
