import * as spanish from "../../translations/es.json";
import * as english from "../../translations/en.json";
import * as portuguese from "../../translations/pt.json";
import * as french from "../../translations/fr.json";
import * as deutsch from "../../translations/de.json";

const es = spanish["default"] ? spanish["default"] : spanish;
const en = english["default"] ? english["default"] : english;
const pt = portuguese["default"] ? portuguese["default"] : portuguese;
const fr = french["default"] ? french["default"] : french;
const de = deutsch["default"] ? deutsch["default"] : deutsch;

const translations = {
  en,
  es,
  pt,
  fr,
  de,
};

export default translations;
