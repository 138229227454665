import styles from "./styles.module.css";
import { Outlet } from "react-router";
import * as React from "react";

export const StartChallengeExtensionLayout: React.FC = () => {
  return (
    <div className={styles.startChallengeExtensionLayout}>
      <Outlet />
    </div>
  );
};
