import { useContext, useEffect, useMemo, useState } from "react";
import { TeamsContext } from "../teamsPage/contexts/TeamsContext";
import * as microsoftTeams from "@microsoft/teams-js";
import { Context } from "@microsoft/teams-js";

export const useIsTeams = () => {
  const context = useContext(TeamsContext);

  return !!context;
};

const timeout = 300;

interface IIsTeamsLoadingState {
  loading: boolean;
  isTeams: boolean | null;
}

export const useTeamsInit = () => {
  const [state, setState] = useState<IIsTeamsLoadingState>({
    loading: true,
    isTeams: null,
  });

  useEffect(() => {
    new Promise<boolean>((resolve, reject) => {
      try {
        microsoftTeams.app
          .initialize()
          .then(() => resolve(true))
          .catch((e) => {
            resolve(false);
          });
        setTimeout(() => {
          resolve(false);
        }, timeout);
      } catch (e) {
        reject(e);
      }
    }).then((result) => {
      setState({
        loading: false,
        isTeams: result,
      });
    });
  }, []);

  return state;
};

/** @deprecated use useContext(TeamsContext) */
export const useLoadableTeamsContext = () => {
  const [context, setContext] = useState<Context | null>(null);
  const isTeamsLoading = useTeamsInit();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isTeamsLoading.loading) {
      if (isTeamsLoading.isTeams) {
        microsoftTeams.getContext((c: microsoftTeams.Context) => {
          setContext(c);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [isTeamsLoading]);

  return useMemo(
    () => ({
      loading,
      context,
    }),
    [context, loading]
  );
};
