import * as React from "react";
import { Outlet, useNavigate } from "react-router";
import { useAuthContext } from "../processes/auth/AuthContext";
import { useCheckAuthWithReferral } from "../utils/use/useCheckAuthWithReferral";
import { FullScreenLoader } from "./FullScreenLoader";

export const ProtectedLayout: React.FC = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const isSkipAuthWithReferral = useCheckAuthWithReferral();

  if (isSkipAuthWithReferral) {
    return <Outlet />;
  }

  if (auth.loading) {
    return <FullScreenLoader />;
  }

  if (!auth.authorized) {
    navigate(`/registration${window.location.search}`);
  }

  return <Outlet />;
};
