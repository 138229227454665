import * as React from "react";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { FullScreenLoader } from "./FullScreenLoader";

export const Redirect: React.FC<{ readonly to: string }> = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [to, navigate]);

  return <FullScreenLoader />;
};
