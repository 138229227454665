import { get, isArray, isString } from "lodash";
import translations from "./translationsFromJson";

export const getTranslation = (locale: string, key: string) => {
  if (key.indexOf("commands.") === 0) {
    const translation = get(translations[locale], key);

    if (isString(translation)) {
      return translation.toLowerCase();
    }

    if (isArray(translation)) {
      return translation.map((item) => item.toLowerCase());
    }
  }
  return get(translations[locale], key);
};
