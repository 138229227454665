import { useCallback, useContext } from "react";
import { IEventTelemetry } from "@microsoft/applicationinsights-common";
import { ICustomProperties } from "@microsoft/applicationinsights-core-js";
import { AppInsightsContext } from "../../teamsPage/contexts/AppInsightsContext";
import { IUser } from "../../common/types/IUser";
import { extractTenantName } from "../../common/utils/extractTenantName";

// @deprecated - use useContext(AppInsightsContext)
export const useGetTrackEvent = () => {
  const appInsights = useContext(AppInsightsContext);
  return useCallback(
    (event: IEventTelemetry, customProperties?: ICustomProperties) => {
      if (!appInsights) {
        throw new Error("AppInsightsContext.Provider is not found.");
      }
      appInsights.trackEvent(event, {
        ...customProperties,
      });
    },
    [appInsights]
  );
};

export const formatUserInfo = (user: IUser) => {
  const email = user?.email;
  const msUserId = user?.msUserId;
  const msTenantId = user?.tenant?.msTenantId;

  const userProps: Record<string, string> = {};

  if (email) {
    userProps.tenant = extractTenantName(email);
    userProps.email = email;
  }

  if (msUserId) {
    userProps.msUserId = msUserId;
  }

  if (msTenantId) {
    userProps.msTenantId = msTenantId;
  }

  return userProps;
};
