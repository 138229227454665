import * as React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import Toolbar from "@mui/material/Toolbar";
import { SignOutButton } from "./SignOutButton";
import { SignInButton } from "./SignInButton";
import styled from "styled-components";

const logo = `${process.env.REACT_APP_ASSETS_HOST}/images/landing/logo.png`;

const AppBarStyled = styled.div`
  height: 160px;
  background-color: #4a85dd;
  padding-top: 75px;
  color: white;
`;

const BreakthruLogo = styled.img`
  width: 290px;
`;

const Header = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppBarStyled>
      <Toolbar className="justify-between">
        <BreakthruLogo src={logo} alt="Breakthru" />
        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      </Toolbar>
    </AppBarStyled>
  );
};

export default Header;
