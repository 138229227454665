import translations from "./translationsFromJson";

const getAvailableLocales = () => Object.keys(translations);

export const parseLocale = (locale?: string) => {
  if (!locale) {
    return getAvailableLocales()[0];
  }

  const simpleLocal = locale.split("-")[0];

  if (simpleLocal && translations[simpleLocal]) {
    return simpleLocal;
  }

  return getAvailableLocales()[0];
};
