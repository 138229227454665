import * as React from "react";
import { FC, useEffect, useMemo, useState } from "react";
import { WelcomeGuideContext } from "./WelcomeGuideContext";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useCountPlaysData } from "../../teamsPage/history/modules/CountPlays/CountPlays";

const WELCOME_GUIDE_VIEWED = "WELCOME_GUIDE_VIEWED";

export const WelcomeGuideProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [stepMood, setStepMood] = React.useState<number>(-1);
  const [step, setStep] = useState(1);
  const [viewed, setViewed] = useLocalStorage(WELCOME_GUIDE_VIEWED, false);
  const [start, setStart] = useState(false);
  const countPlaysData = useCountPlaysData();

  useEffect(() => {
    // start guide until user have played
    if (countPlaysData.fetching === false && countPlaysData.data?.count === 0) {
      setStart(true);
    }
  }, [countPlaysData]);

  useEffect(() => {
    if (viewed || !start) {
      return () => {};
    }

    const id = setInterval(() => {
      let current;
      setStep((s) => {
        current = s + 1;
        return s + 1;
      });

      if (current === 5) {
        clearInterval(id);
      }
    }, 5000);
    return () => clearInterval(id);
  }, [viewed, start]);

  useEffect(() => {
    let id;

    if (step === 2 || step === 4) {
      setStepMood(0);
      id = setInterval(() => {
        let current;
        setStepMood((s) => {
          current = s + 1;
          return s + 1;
        });

        if (current === 4) {
          clearInterval(id);
        }
      }, 1200);
    }
    return () => (id ? clearInterval(id) : undefined);
  }, [step]);

  const showGuide = !viewed && start;

  const value = useMemo(() => {
    return { stepMood, step, showGuide, setViewed };
  }, [stepMood, step, showGuide, setViewed]);

  return (
    <WelcomeGuideContext.Provider value={value}>
      {children}
    </WelcomeGuideContext.Provider>
  );
};
