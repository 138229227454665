import * as React from "react";
import { FC, useEffect, useState } from "react";
import { MeetingSocketContext } from "./MeetingSocketContext";
import { useSocket } from "./use/useSocket";
import { LiveEventEnum } from "../../teamsPage/config/liveEvent.enum";

export const MeetingSocketProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const value = useSocket();
  const [sharingIsLocked, setSharingIsLocked] = useState(false);

  useEffect(() => {
    value.socket?.on(LiveEventEnum.lockShareEventName, () => {
      setSharingIsLocked(() => true);
    });
    value.socket?.on(LiveEventEnum.unlockShareEventName, () => {
      setSharingIsLocked(() => false);
    });

    return () => {
      value.socket?.off(LiveEventEnum.lockShareEventName);
      value.socket?.off(LiveEventEnum.unlockShareEventName);
    };
  }, [value.socket]);

  return (
    <MeetingSocketContext.Provider value={{ ...value, sharingIsLocked }}>
      {children}
    </MeetingSocketContext.Provider>
  );
};
