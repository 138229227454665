import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig";
import Button from "@mui/material/Button";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: "popup" | "redirect" | string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  };

  return (
    <Button
      className="ml-auto"
      variant="outlined"
      color="inherit"
      onClick={() => handleLogin("redirect")}
    >
      Sign in
    </Button>
  );
};
