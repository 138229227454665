import { createContext } from "react";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";

export interface IMeetingStageContext {
  socket?: Socket<DefaultEventsMap, DefaultEventsMap> | null;
  sharingIsLocked?: boolean;
}

export const MeetingSocketContext = createContext<IMeetingStageContext | null>(
  null
);
