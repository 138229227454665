import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { LiveShareClient } from "@microsoft/live-share";
import { LiveShareHost } from "@microsoft/teams-js";
import { SharedMap } from "fluid-framework";
import { TeamsContext } from "../../../teamsPage/contexts/TeamsContext";

type IUsersValue = Array<{
  userId: string;
  mood: string;
  connected: boolean;
  name: string;
}>;

export const useMeetingLiveShareValue = () => {
  const [users, setUsers] = useState<IUsersValue>([]);
  const [usersMap, setUsersMap] = useState<SharedMap>();
  const [joined, setJoined] = useState(false);
  const teamsContext = useContext(TeamsContext);

  const joinHost = useCallback(
    async (initialObject: { userId: string; name: string }) => {
      const host = LiveShareHost.create();
      const liveShareClient = new LiveShareClient(host);
      const schema = {
        initialObjects: {
          usersMap: SharedMap,
        },
      };
      const { container } = await liveShareClient.joinContainer(schema);
      const um = container.initialObjects.usersMap as SharedMap;

      setUsersMap(um);

      // Register listener for changes to values in the map
      um.on("valueChanged", (changed: any) => {
        const changedUser = um.get(changed.key);

        setUsers((us) => {
          if (us.find((u) => u.userId === changedUser.userId)) {
            return us.map((u) =>
              u.userId === changedUser.userId ? changedUser : u
            );
          } else {
            return [...us, changedUser];
          }
        });
      });

      um.set(initialObject.userId, {
        ...initialObject,
        connected: true,
        mood: null,
      });
      setJoined(true);
    },
    []
  );

  // * join host
  useEffect(() => {
    if (!teamsContext) {
      return;
    }

    if (!teamsContext.chatId) {
      return;
    }

    if (!usersMap) {
      joinHost({
        userId: teamsContext.userObjectId as string,
        name: teamsContext.userDisplayName as string,
      });
    }

    return () => {
      if (usersMap && teamsContext?.userObjectId) {
        const prev = usersMap.get(teamsContext.userObjectId);
        usersMap.set(teamsContext.userObjectId, {
          ...prev,
          connected: false,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinHost, usersMap]);

  const setMood = useCallback(
    (mood: string) => {
      if (usersMap && teamsContext?.userObjectId) {
        const prev = usersMap.get(teamsContext.userObjectId);

        usersMap.set(teamsContext.userObjectId, {
          ...prev,
          connected: true,
          mood,
        });
      }
    },
    [teamsContext, usersMap]
  );

  return useMemo(() => {
    return {
      joined,
      users,
      setMood,
      currentUser: {
        meetingId: teamsContext?.chatId,
        userId: teamsContext?.userObjectId,
      },
    };
  }, [joined, users, teamsContext, setMood]);
};
