import styled from "styled-components";
import Button from "@mui/material/Button";

export const MuiButtonStyled = styled(Button)`
  text-transform: inherit !important;
  font-family: inherit !important;
  width: 230px;
  img {
    height: 32px;
    width: 32px;
  }
`;
