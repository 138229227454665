import * as React from "react";
import { FC } from "react";
import { useMeetingLiveShareValue } from "./use/useMeetingLiveShareValue";
import { MeetingLiveShareContext } from "./MeetingLiveShareContext";

export const MeetingLiveShareProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const value = useMeetingLiveShareValue();

  return (
    <MeetingLiveShareContext.Provider value={value}>
      {children}
    </MeetingLiveShareContext.Provider>
  );
};
