import * as React from "react";
import Button from "@mui/material/Button";
import { iconUrl } from "./CameraEnableDialog.styles";
import { Title, Image, Text } from "./CameraEnableDialog.styles";
import { getTranslation } from "../../common/utils/translations/getTranslation";
import { useLocale } from "../../utils/use/useLocale";
import cn from "classnames";

import styles from "./styles.module.css";

export const CameraEnableDialogContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const locale = useLocale();

  return (
    <div
      className={cn(
        "flex flex-col gap-1 text-center relative p-4 items-center",
        styles.cameraEnablePopupContent
      )}
    >
      <Image className="mx-auto" src={iconUrl} alt="" />
      <Title>{getTranslation(locale, "EnableCameraAccess")}</Title>
      <Text>{getTranslation(locale, "ForTheFullBreakthruExperience")}</Text>
      <Button className="w-auto" variant="outlined" onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
