import { useContext, useEffect, useMemo, useState } from "react";
import io, { Socket } from "socket.io-client";
import { AppInsightsContext } from "../../../teamsPage/contexts/AppInsightsContext";
import { TeamsContext } from "../../../teamsPage/contexts/TeamsContext";

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const teamsContext = useContext(TeamsContext);
  const appInsights = useContext(AppInsightsContext);

  useEffect(() => {
    const socket = io({
      query: {
        meetingId: teamsContext?.chatId,
        userId: teamsContext?.userObjectId,
      },
      transports: ["websocket"],
    });
    setSocket(socket);

    socket.on("connect_error", (exception) => {
      appInsights?.trackException({
        exception,
      });
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      socket,
    };
  }, [socket]);
};
