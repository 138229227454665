import * as React from "react";
import { useEffect, useState } from "react";
import { CameraEnableDialogContent } from "./CameraEnableDialogContent";
import { isMobileOrTable } from "../../utils/isMobileOrTable";
import { useIsTeams } from "../../utils/useIsTeams";
import {
  CameraError,
  CameraErrorsMapComponents,
  mapCameraError,
} from "./constants";
import { useAuthContext } from "../../processes/auth/AuthContext";
import { Dialog } from "../../ui/Dialog/Dialog";

import styles from "./styles.module.css";

import { Arrow } from "./CameraEnableDialog.styles";

export const CameraEnableDialog = () => {
  const [error, setError] = useState<CameraError | null>(null);
  const { user } = useAuthContext();
  const isMobile = isMobileOrTable();
  const isTeams = useIsTeams();

  useEffect(() => {
    const handle = (event) => {
      if (event.data.eventName === "cameraNotAvailable") {
        const domain = user?.email.split("@")[1];
        console.log(
          "event.data.value",
          event.data.value,
          domain,
          mapCameraError(event.data.value, domain, isTeams)
        );
        setError(mapCameraError(event.data.value, domain, isTeams));
      }
    };
    window.addEventListener("message", handle, false);
    return () => window.removeEventListener("message", handle);
  }, [user, isTeams]);

  const closeHandler = () => {
    setError(null);
  };

  const Content =
    error === null
      ? CameraEnableDialogContent
      : CameraErrorsMapComponents[error];

  if (isMobile) {
    return null;
  }

  if (error === null) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onCancel={closeHandler}
      maxWidth="md"
      content={
        <>
          {error === CameraError.CameraDisabled && (
            <Arrow className={styles.arrowPosition} />
          )}
          <Content onClose={closeHandler} />
        </>
      }
    />
  );
};
