import * as React from "react";
import styled from "styled-components";
import cn from "classnames";
import Title from "../../components/Title";
import { getTranslation } from "../../../../common/utils/translations/getTranslation";
import { useLocale } from "../../../../utils/use/useLocale";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import { useEffect, useReducer } from "react";
import {
  fetchDataActionAxios,
  fetchDataReducer,
  initialState,
} from "../../../../utils/reducer";
import { useAuthContext } from "../../../../processes/auth/AuthContext";
import { endpoints } from "../../../../common/constants";
import Loader from "@mui/material/CircularProgress";
import { useIsTeams } from "../../../../utils/useIsTeams";

const CountPlaysBlock = styled.div`
  background: url(${process.env
        .REACT_APP_ASSETS_HOST}/images/history-tab/web_history_counter_mobile.png)
      no-repeat center/cover,
    white;
  height: 156px;
  border: 1px solid #e2e8f0;
`;
const CountPlayNumber = styled.div`
  font-size: 60px;
  text-align: right;
  font-weight: 600;
`;

export const useCountPlaysData = () => {
  const [state, dispatch] = useReducer(
    fetchDataReducer<{ count: number }>(),
    initialState
  );
  const auth = useAuthContext();

  useEffect(() => {
    if (auth.authorized) {
      fetchDataActionAxios(dispatch, {
        url: endpoints.getUsersStatisticsCount,
      });
    }
  }, [auth]);

  return state;
};

const CountPlays = () => {
  const locale = useLocale();
  const countPlays = useCountPlaysData();
  const isTeams = useIsTeams();

  return (
    <CountPlaysBlock
      className={cn("text-white p-5 w-full", isTeams && "sm:w-1/2")}
    >
      <Title color="default">
        {getTranslation(locale, "YourTotalBreakthrus")}
      </Title>
      {countPlays.fetching || !countPlays.data ? (
        <Loader />
      ) : (
        <CountPlayNumber>
          {numberWithCommas(countPlays.data.count)}
        </CountPlayNumber>
      )}
    </CountPlaysBlock>
  );
};

export default CountPlays;
