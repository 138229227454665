import * as React from "react";
import Button from "@mui/material/Button";
import { iconUrl } from "./CameraEnableDialog.styles";
import { Title, Image, Text } from "./CameraEnableDialog.styles";

export const CameraNotFoundDialogContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <div className="text-center relative p-4">
      <Image className="mx-auto" src={iconUrl} alt="" />
      <Title>Camera is not found.</Title>
      <Text>We could not find cameras on your device.</Text>
      <Button variant="outlined" onClick={onClose} className="mt-5">
        Close
      </Button>
    </div>
  );
};
