import * as React from "react";
import { MuiButtonStyled } from "./MuiButtonStyled";
import { useAuthContext } from "../../processes/auth/AuthContext";

const msIcon = `${process.env.REACT_APP_ASSETS_HOST}/images/icons/icon-microsoft.svg`;

export const SignInWithMicrosoftButton = (props) => {
  const auth = useAuthContext();

  return (
    <MuiButtonStyled
      {...props}
      variant="outlined"
      onClick={auth.signInMicrosoft}
    >
      <img className="mr-2" src={msIcon} alt="" />
      <span className="pt-1">Sign In with Microsoft</span>
    </MuiButtonStyled>
  );
};
