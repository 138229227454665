import * as React from "react";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { MoodsContext, PrioritiesType } from "./MoodsContext";
import { endpoints } from "../../../../common/constants";
import {
  getProcessEnvDomainUrl,
  IMood,
  ISanityMood,
  moods,
} from "../../../../common/services/moods";
import { getDemoUrl } from "../../../../common/services/demo";
import * as microsoftTeams from "@microsoft/teams-js";
import { parseEntityId } from "../../../../common/utils/deepLinkContextUtils";
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import {
  fetchDataActionAxios,
  fetchDataReducer,
  initialState,
} from "../../../../utils/reducer";

export const mergeAndFilterMoods = (
  activeMoods: ISanityMood[],
  priorities: PrioritiesType
): IMood[] => {
  return moods.reduce((acc, mood) => {
    if (!priorities[mood.mood]) {
      return acc;
    }

    let currentMood: ISanityMood;
    const priority = priorities[mood.mood].order;
    const currentMoodScenes = activeMoods.filter((m) => m.mood === mood.mood);

    if (priorities[mood.mood].title) {
      const found = activeMoods.find(
        (m) => m.title === priorities[mood.mood].title
      );

      if (found) {
        currentMood = found;
      } else {
        currentMood = currentMoodScenes[0];
      }
    } else {
      currentMood = currentMoodScenes[priority];
    }

    if (currentMoodScenes.length === 0) {
      return acc;
    }

    if (!currentMood) {
      currentMood = currentMoodScenes[0];
    }

    const url = getProcessEnvDomainUrl(currentMood);
    const demoUrl = getDemoUrl(mood.mood);
    return [
      ...acc,
      { color: mood.color, ...currentMood, url: demoUrl ? demoUrl : url },
    ];
  }, [] as IMood[]);
};

const SCENES_PRIORITIES = "SCENES_PRIORITIES";
const initialScenesProperties = {
  joyful: { order: 0 },
  confident: { order: 0 },
  centered: { order: 0 },
  energized: { order: 0 },
  surpriseMe: { order: 0 },
  breathe: { order: 0 },
};

export const MoodsProvider: FC<React.PropsWithChildren> = (props) => {
  const [sanityMoodsState, dispatch] = useReducer(
    fetchDataReducer<{ result: ISanityMood[] }>(),
    initialState
  );
  const [priorities, setPriorities] = useLocalStorage<PrioritiesType>(
    SCENES_PRIORITIES,
    initialScenesProperties
  );

  const [initialPriorities, setInitialPriorities] = useState(priorities);

  useEffect(() => {
    fetchDataActionAxios(dispatch, {
      url: endpoints.moods,
    });
  }, []);

  useEffect(() => {
    if (microsoftTeams.app.isInitialized()) {
      microsoftTeams.app.getContext().then((context) => {
        if (context.page.subPageId) {
          const obj = parseEntityId(context.page.subPageId);
          if (!obj) {
            return;
          }

          // for scene announcements possible to pick mood title
          if (obj.priorityMoodTitle) {
            const prevOrder = priorities[obj.value]?.order ?? 0;
            setPriorities({
              ...priorities,
              [obj.value]: { title: obj.priorityMoodTitle, order: prevOrder },
            });

            setInitialPriorities({
              ...priorities,
              [obj.value]: { title: obj.priorityMoodTitle, order: prevOrder },
            });
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const setPrioritiesForNextScene = useCallback(
    (currentMood: string) => {
      const p = initialPriorities[currentMood];
      if (p.title) {
        // remove previous title as priority
        setPriorities({
          ...initialPriorities,
          [currentMood]: { order: p.order },
        });
      } else {
        const moodsLength =
          sanityMoodsState.data?.result.filter((m) => m.mood === currentMood)
            .length ?? 100;

        // update order number to next scene
        setPriorities({
          ...initialPriorities,
          [currentMood]: { order: (+p.order + 1) % moodsLength },
        });
      }
    },
    // eslint-disable-next-line
    [initialPriorities, sanityMoodsState.data]
  );

  const setOrderForSurpriseMe = useCallback(() => {
    const moodsLength =
      sanityMoodsState.data?.result.filter((m) => m.isSurpriseMeType).length ??
      100;

    const order = initialPriorities.surpriseMe?.order ?? 0;

    setPriorities({
      ...initialPriorities,
      surpriseMe: {
        order: (+order + 1) % moodsLength,
      },
    });
    // eslint-disable-next-line
  }, [initialPriorities, sanityMoodsState.data]);

  const value = useMemo(() => {
    const filteredMoods = sanityMoodsState.data?.result
      ? mergeAndFilterMoods(sanityMoodsState.data?.result, initialPriorities)
      : [];

    const surpriseMeMoods =
      sanityMoodsState.data?.result.filter((m) => m.isSurpriseMeType) ?? [];

    return {
      filteredMoods,
      moods: sanityMoodsState.data?.result ?? [],
      surpriseMeMood: surpriseMeMoods[initialPriorities.surpriseMe?.order ?? 0],
      setOrderForSurpriseMe,
      setPrioritiesForNextScene,
    };
  }, [
    setOrderForSurpriseMe,
    sanityMoodsState.data,
    initialPriorities,
    setPrioritiesForNextScene,
  ]);

  return (
    <MoodsContext.Provider value={value}>
      {props.children}
    </MoodsContext.Provider>
  );
};
