import * as React from "react";
import { Route } from "react-router";
import { TeamsLayout } from "../ui/TeamsLayout";
import { ProtectedLayout } from "../ui/ProtectedLayout";
import { PlayPageRouter } from "./play/PlayPageRouter";
import { Redirect } from "../ui/Redirect";
import { TeamsMessageExtensionRouter } from "./breakthruMessageExtension/TeamsMessageExtensionRouter";
import { MeetingPageRouter } from "./meeting/MeetingPageRouter";

const HistoryTab = React.lazy(() => import("./history/HistoryPage"));
const ChallengesPage = React.lazy(() => import("./challenges/ChallengesPage"));
const ReminderApprove = React.lazy(
  () => import("./reminders/pages/ReminderApprovePage")
);
const MessageTask = React.lazy(() => import("./messageTask/MessageTask"));

const RemindersList = React.lazy(
  () => import("./reminders/pages/RemindersListPage")
);
const ReminderForm = React.lazy(
  () => import("./reminders/pages/ReminderForm/ReminderForm")
);
const RegistrationPage = React.lazy(
  () => import("./registration/RegistrationPage")
);
const UserNotInstalledApp = React.lazy(
  () => import("./userNotInstalledApp/UserNotInstalledApp")
);
const AchievementsPage = React.lazy(
  () => import("./achievements/AchievementsPage")
);

export const TeamsPageRouter = (
  <Route element={<TeamsLayout />}>
    {TeamsMessageExtensionRouter}
    <Route path="/registration" element={<RegistrationPage />} />
    <Route path="/reminders/create" element={<ReminderForm />} />
    <Route path="/reminders" element={<RemindersList />} />
    {MeetingPageRouter}
    <Route path="/reminder-approve/:type" element={<ReminderApprove />} />
    <Route path="/message/:messageCode" element={<MessageTask />} />
    <Route path="/userNotInstalledApp" element={<UserNotInstalledApp />} />
    <Route element={<ProtectedLayout />}>
      {PlayPageRouter}
      <Route path="/history" element={<HistoryTab />} />
      <Route path="/achievements" element={<AchievementsPage />} />
      <Route path="/challenges" element={<ChallengesPage />} />
      <Route path="/*" element={<Redirect to="/tab" />} />
    </Route>
  </Route>
);
