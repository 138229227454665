export const colors = {
  default: "#515151",
  white: "#ffffff",
  confident: "#ed8936",
  centered: "#48bb78",
  energized: "#4299e1",
  joyful: "#ecc94b",
  teams: "#6264a7",
  teamsBorders: "rgba(224, 217, 217, 1)",
  teamsBordersDarkTheme: "rgba(224, 217, 217, .3)",
};

export const themes = {
  dark: {
    type: "dark",
    color: colors.white,
    background: colors.default,
  },
  default: {
    type: "default",
    color: colors.default,
    background: colors.white,
  },
};

export const mediaBreakpoints = {
  mobile: "@media screen and (max-width: 599px)",
  xsOnly: "@media screen and (max-width: 640px)",
  sm: "@media screen and (min-width: 640px)",
  md: "@media screen and (min-width: 768px)",
  lg: "@media screen and (min-width: 1024px)",
};

export enum breakpoints {
  mobile = "(max-width: 599px)",
  xsOnly = "(max-width: 640px)",
  sm = "(min-width: 640px)",
  md = "(min-width: 768px)",
  lg = "(min-width: 1024px)",
}

export const fontFamily = "Campton-Book, sans-serif";
